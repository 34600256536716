/* website: 3636-bellevilletoyota1
 * created at 2022-01-11 15:55 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/toyota.scss";
@import "../utils/icons.scss";

@include breakpoint(mobile){
    .widget-sr {
        &.dealer__belleville-toyota {
            .sr-topbar-login{
                padding: 0;
            }
            .LoginBanner .login-content{
                padding-right: 0;
            }
            .LoginBanner .btn-login .label,
            .LoginBanner .btn-login icon{
                font-size: 13px;
            }
            .LoginBanner .LoginBannerWrapper{
                min-height: 40px;
                max-height: 40px;
                .login-content{
                    display: flex;
                    #button-login.btn-login{
                        margin-left: 0;
                    }
                    #button-register.btn-register{
                        margin-left: 10px;
                    }
                }
            }
      }
    }
  }

.widget-sr{
    @include clearfix;
    width: 100%;
    &.dealer__belleville-toyota{
        .payment_infos_wrapper--advantages-listing li.sr-text:nth-child(2){
            display:none;
        }
    }
}